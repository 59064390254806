import intl = require("./intl");
import { IStateMachine, STATE } from "./sm";
import trackEvent = require("./track-event");
import { IUI } from "./ui";

const MS_PER_HOUR = 60 * 60 * 1000;

let newsData: any;

function loadNews() {
    $.ajax({
        url: "https://fmcafe.online/news.json",
        dataType: "json",
        cache: false,
        success: (response) => newsData = response
    });
}

function tryDisplayNews(ui: IUI) {
    if (!newsData || Date.now() > newsData.until) {
        return false;
    }

    const text = newsData.text;
    const url = newsData.url;
    const anchor = $("<a></a>").text(text);

    // eslint-disable-next-line no-misleading-character-class
    const isEmoji = /^[\u2300-\u2bff\ud800-\uf8ff\ufe0f]+$/g.test(text);
    if (!isEmoji) {
        anchor.css("text-shadow", "#e0e0e0 0 0 6px");
    }

    if (url) {
        anchor
            .attr({ target: "_blank", href: url })
            .click(() => trackEvent("UI", "News click", text));
    }

    ui.setStatusText((statusNode) => {
        statusNode.append(anchor);
    });

    return true;
}

function initSponsorButton() {
    const hintKey = "SPONSOR_BUTTON";
    intl.register(hintKey, "en", "Donate");
    intl.register(hintKey, "ru", "Поддержать проект материально");

    const anchor = $("<a></a>")
        .attr({
            id: "sponsor-heart",
            target: "_blank",
            href: "https://boosty.to/fmcafe.online/about",
            title: intl.t(hintKey)
        })
        .click(() => trackEvent("UI", "Heart click"));

    $("#content").append(anchor);
}

export = (ui: IUI, sm: IStateMachine) => {
    loadNews();
    setInterval(loadNews, MS_PER_HOUR / 2);

    sm.stateChanged.add(() => {
        switch (sm.state()) {
            case STATE.PLAYING:
            case STATE.BUFFERING:
            case STATE.STOPPED:
                tryDisplayNews(ui);
                break;
        }
    });

    if (!ANDROID_APP) {
        // https://stackoverflow.com/q/17783664
        initSponsorButton();
    }
};
