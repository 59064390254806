import androidFriendly = require("./android-friendly");
import env = require("./env");
import { createMetadata, ITrackMetadata } from "./metadata";

const CONTENT_SERVER_URL = "https://cafe-content.amartynov.ru";

const HLS = ANDROID_APP || (env.ios || env.desktopSafari || env.oldEdge)
    && env.canPlayType("application/vnd.apple.mpegurl");

type Cue = Readonly<[string, string, number]>;

let currentCue: Cue;
let currentCueFetchTime = 0;
let currentCueValidUntil = 0;

function url() {
    const result = CONTENT_SERVER_URL + (HLS ? "/hls/stream.m3u8" : "/ice");
    if (!HLS) {
        // https://stackoverflow.com/a/13486198
        return result + "?_=" + Date.now();
    }
    return result;
}

function fetchCurrentCue() {
    const now = Date.now();
    if (now > currentCueValidUntil && now - currentCueFetchTime > 2000) {
        currentCueFetchTime = now;
        androidFriendly.getJSON(CONTENT_SERVER_URL + "/current-cue.json", (r) => currentCue = r);

        androidFriendly.getJSON(CONTENT_SERVER_URL + "/current-cue-left.json", (r: number) => {
            if (r > 7 * 30) {
                r = 6 * 30;
            }
            currentCueValidUntil = now + 1000 * r;
        });
    }
}

function currentCueAsMetadata(): ITrackMetadata {
    if (!currentCue || !currentCue[0] || currentCue[0] === "ID") {
        return createMetadata("Alexander Nuzhdin", "fmcafé");
    }

    return createMetadata(currentCue[0], currentCue[1], currentCue[2], true);
}

export = {
    HLS,
    url,
    fetchCurrentCue,
    currentCueAsMetadata
} as const;
