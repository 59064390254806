import env = require("./env");

const ENABLED = !env.mobile;

let img: HTMLImageElement;
let requestedSrc = "";
let requestedVisible = false;

function stateHasChanged() {
    const outdated = img.src !== requestedSrc;
    const loaded = img.complete && img.naturalWidth > 0;
    if (requestedVisible && loaded && !outdated) {
        $(img).delay(100).fadeIn();
    } else {
        $(img).stop(true).fadeOut(() => {
            if (outdated) {
                img.src = requestedSrc;
            }
        });
    }
}

function init() {
    img = new Image();
    $(img)
        .attr("id", "coverart")
        .hide()
        .on("load", () => stateHasChanged())
        .insertAfter($("#infobar"));
}

function updateImage(src: string) {
    requestedSrc = src;
}

function attachAnchorEvents(anchor: JQuery) {
    anchor
        .on("mouseenter", () => { requestedVisible = true; stateHasChanged(); })
        .on("mouseleave remove", () => { requestedVisible = false; stateHasChanged(); });
}

export = { ENABLED, init, updateImage, attachAnchorEvents } as const;
