import env = require("./env");
import jquiHelper = require("./jqui-helper");
import { IStateMachine, STATE } from "./sm";
import { IVolumeControl } from "./vc";

export = (vc: IVolumeControl, sm: IStateMachine) => {
    if (env.mobile) {
        return;
    }

    sm.stateChanged.add(() => {
        if (sm.state() === STATE.READY) {
            vc.volumeChanged.add(sm.setVolume);
        }
    });

    jquiHelper.lazyLoad().done(() => vc.init());
};
