import coverartPopup = require("./coverart-popup");
import env = require("./env");
import intl = require("./intl");
import { ITrackMetadata } from "./metadata";
import { IStateMachine, STATE } from "./sm";
import trackEvent = require("./track-event");
import { BUTTON_MODE, IUI } from "./ui";

function playStateToButtonMode(playState: STATE) {
    switch (playState) {
        case STATE.PLAYING:
            return BUTTON_MODE.STOP;

        case STATE.STOPPED:
            return BUTTON_MODE.PLAY;

        case STATE.BUFFERING:
            return BUTTON_MODE.BUFFERING;
    }

    return BUTTON_MODE.EMPTY;
}

export = (ui: IUI, sm: IStateMachine) => {

    function handleMetadataChanged(metadata: ITrackMetadata) {
        const url = metadata.url;
        const content = new Array<any>();

        if (url) {
            const a = $("<a target=_blank>")
                .attr("href", url)
                .on("click", () => trackEvent("UI", "Cue Click"));

            if (metadata.shid && coverartPopup.ENABLED) {
                coverartPopup.attachAnchorEvents(a);
            }

            content.push(a);
        } else {
            content.push($("<span>"));
        }

        const title = String(metadata.title).replace(/_/g, " ");
        content[0].text(title);
        content.push(" – " + metadata.artist);

        ui.setInfoText((statusNode) => statusNode.append(content));

        if (coverartPopup.ENABLED) {
            coverartPopup.updateImage(metadata.coverartUrl(false));
        }

        if (!env.mobile && !env.webApp) {
            metadataToDocTitle(metadata);
        }
    }

    function metadataToDocTitle(metadata: ITrackMetadata) {
        const fmcafe = "fmcafé";
        const { title: trackTitle, artist: trackArtist } = metadata;
        const buf = new Array<string>();

        if (trackTitle !== fmcafe) {
            buf.push(trackTitle, " – ", trackArtist, " – ");
        }

        buf.push(fmcafe);

        document.title = buf.join("");
    }

    sm.stateChanged.add(() => {
        ui.updateButton(playStateToButtonMode(sm.playState()));

        switch (sm.state()) {
            case STATE.INIT:
                ui.setStatusText(intl.t("LOADING") + "…");
                break;

            case STATE.READY:
            case STATE.BUFFERING:
            case STATE.STOPPED:
                ui.setStatusText("");
                break;

            case STATE.PLAYING:
                ui.setStatusText((statusNode) => $("<div>")
                    .addClass("playing")
                    .appendTo(statusNode));
                break;

            case STATE.ERROR:
                ui.setStatusText(intl.t("ERROR") + "!");
                ui.setInfoText(sm.lastError());
                trackEvent("UI", "Error", sm.lastError());
                break;
        }
    });

    sm.metadataChanged.add(handleMetadataChanged);

    ui.buttonClick.add(() => {
        switch (sm.playState()) {
            case STATE.PLAYING:
            case STATE.BUFFERING:
                sm.stop();
                trackEvent("UI", "Stop");
                break;

            case STATE.STOPPED:
                sm.play();
                trackEvent("UI", "Play");
                break;
        }
    });
};
