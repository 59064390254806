const global = window as any;
const ua = navigator.userAgent;
const winPhone = /iemobile|windows phone/i.test(ua);
const ios13DesktopMode = navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1;
const ios = !winPhone && (/iphone|ipad|ipod/i.test(ua) || ios13DesktopMode);
const android = ANDROID_APP || !winPhone && /android/i.test(ua);
const safariObj = global.safari;
const hash = location.hash;

function canPlayType(type: string) {
    try {
        return new Audio().canPlayType(type) !== "";
    } catch {
        return false;
    }
}

export = {
    winPhone,
    ios,
    android,

    mobile: winPhone || ios || android,

    crawler: /googlebot/i.test(ua),

    oldEdge: "CollectGarbage" in global && / Edge\/\d/.test(ua),
    desktopSafari: !ios && safariObj && /Safari/.test(safariObj.pushNotification),

    canPlayType,

    webApp: hash === "#chrome_app" || hash === "#manifest_json"
} as const;
