import IPlayer = require("./player");

export = (): IPlayer => {
    const statusChanged = $.Callbacks();

    return {
        supportsVolume: false,
        supportsTimes: false,
        statusChanged,
        play: () => statusChanged.fire("playing"),
        stop: () => statusChanged.fire("stopped"),
        setVolume: $.noop,
        getTimes: $.noop
    };
};
