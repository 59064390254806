import cache = require("./cache");
import trackEvent = require("./track-event");
import ICON_PATHS = require("./vc-icon-paths");

export interface IVolumeControl {
    readonly volumeChanged: JQueryCallback;
    init(): void;
}

function el(tag: string) {
    return $(document.createElement(tag));
}

export function createVolumeControl(): IVolumeControl {
    const CACHE_KEY = "vol";

    const volumeChanged = $.Callbacks();

    let slider: JQuery;
    let button: JQuery;
    let muteBackup = 0;
    let initialized = false;

    function createMarkup() {
        const popup = el("div")
            .attr("id", "vc-popup");

        button = $("<svg><path></svg")
            .attr("id", "vc-button")
            .attr("viewBox", "0 0 24 24");

        slider = el("div").height("100%").slider({
            orientation: "vertical",
            min: 0,
            max: 100,
            slide: (e, ui) => changeVolume(ui.value, false, true, true),
            change: (e, ui) => {
                if (initialized) {
                    trackEvent("UI", "Volume slider", String(ui.value));
                }
            }
        });

        button.add(popup)
            .mouseenter(() => popup.stop(true).fadeIn(400))
            .mouseleave(() => popup.delay(900).fadeOut(400));

        button.click(() => {
            let value = slider.slider("value");
            if (value > 0) {
                muteBackup = value;
                value = 0;
            } else {
                value = muteBackup || 100;
                muteBackup = 0;
            }
            changeVolume(value, true, true, true);
        });

        $("#content").append(popup.append(slider), button);

        // https://stackoverflow.com/a/12395299
        slider.find("a").removeAttr("href");
    }

    function updateButton(value: number) {
        function selectPath() {
            if (value < 1) {
                return ICON_PATHS.OFF;
            }
            if (value < 50) {
                return ICON_PATHS.MID;
            }
            return ICON_PATHS.FULL;
        }
        button.show().find("path")
            .attr("d", selectPath())
            .css("fill", value ? "" : "#f66");
    }

    function init() {
        createMarkup();
        changeVolume(volumeFromCache(), true, false, true);
        initialized = true;
    }

    function changeVolume(value: number, updateSlider: boolean, updateCache: boolean, fireEvent: boolean) {
        updateButton(value);

        if (updateSlider) {
            slider.slider("value", value);
        }

        if (updateCache) {
            cache.set(CACHE_KEY, String(value));
        }

        if (fireEvent) {
            volumeChanged.fire(value);
        }
    }

    function volumeFromCache() {
        let value = Number(cache.get(CACHE_KEY));
        if (!value) {
            return 100;
        }

        value = Math.min(value, 100);
        value = Math.max(value, 0);
        return value;
    }

    return { volumeChanged, init };
}
