import env = require("./env");
import IPlayer = require("./player");
import stream = require("./stream");

const enum InternalState { DEFAULT, PLAYING, BLANK_STOPPING, BLANK_STOPPED }

export = (): IPlayer => {
    if (ANDROID_APP) {
        throw "N/A";
    }

    const statusChanged = $.Callbacks();
    const audioNode = new Audio();

    if (env.ios) {
        audioNode.title = "fmcafé";
    }

    let internalState = InternalState.DEFAULT;
    let pausingFromStopMethod = false;

    $(audioNode).on({

        playing() {
            switch (internalState) {
                case InternalState.BLANK_STOPPING:
                case InternalState.BLANK_STOPPED:
                    audioNode.pause();
                    break;
                case InternalState.PLAYING:
                    statusChanged.fire("playing");
                    break;
            }
        },

        pause() {
            const fromStopMethod = pausingFromStopMethod;
            pausingFromStopMethod = false;
            switch (internalState) {
                case InternalState.BLANK_STOPPING:
                    internalState = InternalState.BLANK_STOPPED;
                    break;
                case InternalState.BLANK_STOPPED:
                    play();
                    break;
                case InternalState.PLAYING:
                    // eslint-disable-next-line no-case-declarations
                    const { currentTime, duration } = audioNode;
                    if (duration && isFinite(duration) && currentTime === duration) {
                        play();
                        break;
                    }
                    statusChanged.fire("stopped");
                    if (stream.HLS) {
                        internalState = InternalState.DEFAULT;
                    } else {
                        // Stop useless download of Icecast stream
                        audioNode.src = "blank.mp3";
                        if (env.android && !fromStopMethod) {
                            // Chances are it is audio focus loss
                            // Not calling 'play' to not gain it back
                            internalState = InternalState.BLANK_STOPPED;
                            audioNode.load();
                        } else {
                            internalState = InternalState.BLANK_STOPPING;
                            _mutePromise(audioNode.play());
                        }
                    }
                    break;
            }
        },

        error: () => {
            const code = audioNode.error.code;

            // Per HTML5 spec, "aborted by the user agent at the user's request"
            if (code === 1) {
                return;
            }

            statusChanged.fire("error", "code " + code);
            internalState = InternalState.DEFAULT;
        }
    });

    function play() {
        statusChanged.fire("buffering");
        internalState = InternalState.PLAYING;
        audioNode.src = stream.url();
        _mutePromise(audioNode.play());
    }

    function stop() {
        pausingFromStopMethod = true;
        audioNode.pause();
    }

    function setVolume(value: number) {
        audioNode.volume = value / 100;
    }

    function getTimes() {
        return {
            current: audioNode.currentTime,
            total: audioNode.duration
        };
    }

    function _mutePromise(p: Promise<unknown>) {
        if (p && p.catch) {
            p.catch($.noop);
        }
    }

    return {
        supportsVolume: true,
        supportsTimes: true,
        statusChanged,
        play,
        stop,
        setVolume,
        getTimes,
    };
};
