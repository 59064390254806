const CDN_URL = "https://cdnjs.cloudflare.com/ajax/libs/jqueryui/1.12.1";
const THEME_LINK_ID = "jqui_theme_css";

const THEME_HOT = "sunny";
const THEME_WARM = "ui-lightness";
const THEME_COLD = "cupertino";

let pendingTheme = THEME_WARM;

function isLoaded() {
    return "ui" in $;
}

function waitForScriptLoad(d: JQueryDeferred<void>) {
    const startTime = Date.now();
    const loadCheckHandle = setInterval(
        () => {
            const loaded = isLoaded();
            const timedOut = Date.now() - startTime > 5 * 1000;
            if (loaded) {
                d.resolve();
            }
            if (loaded || timedOut) {
                clearInterval(loadCheckHandle);
            }
        },
        300
    );
}

function lazyLoad() {
    const d = $.Deferred<void>();

    if (isLoaded()) {
        d.resolve();
    } else {
        $.ajax({
            dataType: "script",
            cache: true,
            url: "/vendor/jquery-ui-custom.min.js"
        });

        const linkTemplate = "<link rel=stylesheet>";

        $(linkTemplate)
            .attr("href", CDN_URL + "/jquery-ui.structure.min.css")
            .appendTo("head");

        $(linkTemplate)
            .attr("id", THEME_LINK_ID)
            .appendTo("head");

        updateTheme(pendingTheme);
        pendingTheme = null;

        waitForScriptLoad(d);
    }

    return d.promise();
}

function updateTheme(name: string) {
    const link = $("#" + THEME_LINK_ID);

    if (link.length) {
        link.attr(
            "href",
            `${CDN_URL}/themes/${name}/theme.min.css`
        );
    } else {
        pendingTheme = name;
    }
}

export = {
    THEME_HOT,
    THEME_WARM,
    THEME_COLD,
    lazyLoad,
    updateTheme
} as const;
