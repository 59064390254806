import env = require("./env");
import { ITrackMetadata } from "./metadata";
import { IStateMachine } from "./sm";
import trackEvent = require("./track-event");

function trackAction(name: string) {
    trackEvent("UI", "MediaSession " + name);
}

export = (sm: IStateMachine) => {
    if (ANDROID_APP) {
        throw "N/A";
    }

    const mediaSession = navigator.mediaSession;
    const MediaMetadata = window.MediaMetadata;

    if (!mediaSession || !MediaMetadata) {
        return;
    }

    function handlePause() {
        trackAction("Pause");
        sm.stop();
    }

    function handlePlay() {
        trackAction("Play");
        sm.play();
    }

    function handleMetadataChanged(metadata: ITrackMetadata) {
        const artworkItem: MediaImage = {
            src: metadata.coverartUrl(true)
        };

        if (!env.android) {
            artworkItem.sizes = "480x480";
        }

        mediaSession.metadata = new MediaMetadata({
            title: metadata.title,
            artist: metadata.artist,
            artwork: [artworkItem]
        });
        mediaSession.setActionHandler("pause", handlePause);
        mediaSession.setActionHandler("play", handlePlay);

        if (mediaSession.setPositionState) {
            mediaSession.setPositionState({ duration: 0 });
        }
    }

    sm.metadataChanged.add(handleMetadataChanged);
};
