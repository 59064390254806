import cache = require("./cache");
import env = require("./env");
import jquiHelper = require("./jqui-helper");

declare const SunCalc: any;
declare const cordova: any;

const enum THEME {
    DEFAULT,
    WINTER,
    NIGHT,
    HEAT
}

function getThemeName(theme: THEME) {
    switch (theme) {
        case THEME.WINTER: return "winter";
        case THEME.NIGHT: return "night";
        case THEME.HEAT: return "heat";
    }
}

function getJQUIThemeName(theme: THEME) {
    switch (theme) {
        case THEME.WINTER:
        case THEME.NIGHT:
            return jquiHelper.THEME_COLD;
        case THEME.HEAT:
            return jquiHelper.THEME_HOT;
    }
    return jquiHelper.THEME_WARM;
}

function isWinter() {
    const m = new Date().getMonth();
    return m > 10 || m < 2;
}

function isNightAtLocation(lat: number, lon: number) {
    if (!lat || !lon) {
        return false;
    }

    const now = new Date();
    const sun = SunCalc.getTimes(now, lat, lon);
    return now < sun.sunrise || now > sun.dusk;
}

function isDarkPreferred() {
    try {
        return window.matchMedia && matchMedia("(prefers-color-scheme: dark)").matches;
    } catch {
        return false;
    }
}

function updateAndroidStatusBar(theme: THEME) {
    const isLight = theme !== THEME.NIGHT;
    let retries = 0;

    function tryUpdate() {
        try {
            cordova.plugin.utils.setStatusBarLight(isLight);
        } catch {
            if (++retries < 10) {
                setTimeout(tryUpdate, 100);
            }
        }
    }

    tryUpdate();
}

export = () => {
    const LAT_KEY = "night_lat";
    const LON_KEY = "night_lon";
    const FORCE_HEAT_KEY = "heat";

    let lat = Number(cache.get(LAT_KEY));
    let lon = Number(cache.get(LON_KEY));
    let forceHeat = Number(cache.get(FORCE_HEAT_KEY));

    let currentTheme: THEME;

    $.getJSON("https://cafe-content.amartynov.ru/ipapi/").done((geo: any) => {
        const newLat = Number(geo.latitude);
        const newLon = Number(geo.longitude);
        if (!isFinite(newLat + newLon)) {
            return;
        }

        cache.set(LAT_KEY, String(newLat));
        cache.set(LON_KEY, String(newLon));

        lat = newLat;
        lon = newLon;
        update();
    });

    update();
    setInterval(() => update(true), 5 * 60 * 1000);
    initHeatTrigger();

    if (ANDROID_APP) {
        $(document).on("deviceconfigchange", () => update());
    }

    function update(fade?: boolean) {
        const newTheme = calcTheme();
        if (newTheme === currentTheme) {
            return;
        }

        if (fade) {
            if (!ANDROID_APP) {
                preloadTheme(newTheme);
            }
            $("body").fadeOut(function() {
                applyTheme(newTheme);
                $(this).fadeIn();
            });
        } else {
            applyTheme(newTheme);
        }
    }

    function calcTheme() {
        if (!env.crawler) {
            if (forceHeat) {
                return THEME.HEAT;
            }
            if (isDarkPreferred() || isNightAtLocation(lat, lon)) {
                return THEME.NIGHT;
            }
            if (isWinter()) {
                return THEME.WINTER;
            }
        }
        return THEME.DEFAULT;
    }

    function preloadTheme(theme: THEME) {
        const link = document.getElementById("theme_css_pre") as HTMLLinkElement;
        const name = getThemeName(theme);
        if (link && name) {
            link.href = formatThemeURL(name);
        }
    }

    function applyTheme(theme: THEME) {
        const themeLink = $("#theme_css");
        const themeName = getThemeName(theme);
        if (themeName) {
            themeLink.attr("href", formatThemeURL(themeName));
        } else {
            themeLink.removeAttr("href");
        }

        jquiHelper.updateTheme(getJQUIThemeName(theme));
        $("html").toggleClass("night", theme === THEME.NIGHT);

        if (ANDROID_APP) {
            updateAndroidStatusBar(theme);
        } else if (env.ios) {
            const content = theme === THEME.NIGHT ? "black" : "";
            $("meta[name=theme-color]").attr("content", content);
        }

        currentTheme = theme;
    }

    function formatThemeURL(name: string) {
        return `res/${name}.css`;
    }

    function initHeatTrigger() {
        let count = 0;
        $(document).on("mousedown", (e) => {
            const node = e.target as any;
            if (node["fmcafe-heat"]) {
                count++;
                if (count === 7) {
                    count = 0;
                    forceHeat = forceHeat ? 0 : 1;
                    cache.set(FORCE_HEAT_KEY, String(forceHeat));
                    update(true);
                }
            } else {
                count = 0;
            }
        });
    }
};
