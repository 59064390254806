import env = require("./env");
import intl = require("./intl");

export const enum BUTTON_MODE {
    EMPTY,
    PLAY,
    STOP,
    BUFFERING
}

type TextOrFunc = string | ((statusNode: JQuery) => void);

export interface IUI {
    readonly buttonClick: JQueryCallback;
    initMarkup(container: JQuery): void;
    setInfoText(text: TextOrFunc): void;
    setStatusText(text: TextOrFunc): void;
    updateButton(buttonMode: BUTTON_MODE): void;
}

export function createUI(): IUI {
    const buttonClick = $.Callbacks();

    function el(tag: string) {
        return $(document.createElement(tag));
    }

    function initMarkup(container: JQuery) {
        container
            .append(el("div").attr("id", "backstripe1"))
            .append(el("div").attr("id", "backstripe2"))
            .append(el("div").attr("id", "backstripe3"))
            .append(el("div").attr("id", "content")
                .append(el("h1")
                    .append(el("span").addClass("fm").text("fm").prop("fmcafe-heat", 1))
                    .append(el("span").html("caf&eacute;"))
                )
                .append(el("div").attr("id", "button")
                    .append(el("div").addClass("button-glyph"))
                )
                .append(el("div").attr("id", "infobar"))
                .append(el("div").attr("id", "statusbar"))
            );

        buttonElement().click(() => buttonClick.fire());

        infoElement().html("Chillout, Deep, Progressive<br>" + intl.t("SLOGAN"));

        if (!ANDROID_APP) {
            $("#email")
                .show()
                .find("a").attr("title", intl.t("EMAIL_HINT"));

            if (env.ios) {
                // https://stackoverflow.com/a/69589919
                document.body.style.overflow = "hidden";
            }
        }
    }

    function updateButton(buttonMode: BUTTON_MODE) {
        buttonElement()
            .toggleClass("button-play", buttonMode === BUTTON_MODE.PLAY)
            .toggleClass("button-stop", buttonMode === BUTTON_MODE.STOP)
            .toggleClass("button-spinner", buttonMode === BUTTON_MODE.BUFFERING);
    }

    function setStatusText(text: TextOrFunc) {
        setTextCore(statusElement(), text);
    }

    function setInfoText(text: TextOrFunc) {
        const element = infoElement();
        element.fadeOut(1000, () => {
            setTextCore(element, text);
            element.fadeIn(1000);
        });
    }

    function setTextCore(element: JQuery, text: TextOrFunc) {
        if (typeof text === "function") {
            element.empty();
            text(element);
        } else {
            element.text(text);
        }
    }

    function statusElement() {
        return $("#statusbar");
    }

    function infoElement() {
        return $("#infobar");
    }

    function buttonElement() {
        return $("#button");
    }

    return {
        buttonClick,
        initMarkup,
        setInfoText,
        setStatusText,
        updateButton,
    };
}
