const KEY_PREFIX = "fmcafe-v6-";

function get(key: string) {
    try {
        return localStorage.getItem(KEY_PREFIX + key);
    } catch {
        return null;
    }
}

function set(key: string, value: string) {
    try {
        localStorage.setItem(KEY_PREFIX + key, value);
    } catch {
        // mute
    }
}

export = { get, set } as const;
