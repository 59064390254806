import createDomPlayer = require("./create-dom-player");
import createFakePlayer = require("./create-fake-player");
import env = require("./env");
import IPlayer = require("./player");

export = (): IPlayer => {
    if (env.crawler) {
        return createFakePlayer();
    }

    if (env.canPlayType("audio/mpeg")) {
        return createDomPlayer();
    }

    return null;
};
