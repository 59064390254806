import cache = require("./cache");
import env = require("./env");

declare const cordova: any;

type QueryString = Record<string, string | number>;

const GA_ORIGIN = "https://www.google-analytics.com";
const GA_CID_KEY = "ga-cid-2";
const GA_SESSION_DATA_KEY = "ga-sess";

let clientID: string;
let sessionID: number;
let sessionNumber: number;
let sessionEngaged: boolean;

let hitSequenceNumber = 0;
let sendFirstVisit = false;
let sendSessionStart = false;

loadClientID();
loadSession();

function loadClientID() {
    clientID = cache.get(GA_CID_KEY);
    if (!clientID) {
        clientID = rnd() + rnd();
        cache.set(GA_CID_KEY, clientID);
        sendFirstVisit = true;
    }
}

function loadSession() {
    const data = String(cache.get(GA_SESSION_DATA_KEY)).split(".");

    sessionID = Number(data[0]);
    sessionNumber = Number(data[1]) || 0;
    sessionEngaged = Number(data[2]) > 0;

    const savedUnixTime = Number(data[3]) || 0;
    const now = unixTime();
    if (!sessionID || now - savedUnixTime > 30 * 60) {
        sessionID = now;
        sessionNumber++;
        sessionEngaged = false;
        sendSessionStart = true;
    }
}

function saveSession() {
    const data = [ sessionID, sessionNumber, Number(sessionEngaged), unixTime() ].join(".");
    cache.set(GA_SESSION_DATA_KEY, data);
}

function rnd() {
    return Math.random().toString(36).substr(2);
}

function unixTime() {
    return Math.floor(Date.now() / 1000);
}

function isLocalHost(host: string) {
    return host === "localhost" || host.charAt(0) === "1";
}

function send(url: string) {
    if (ANDROID_APP) {
        try {
            cordova.plugin.utils.sendBeacon(url);
        } catch {
            // before deviceready?
        }
    } else {
        const nav = navigator;
        if (nav.sendBeacon) {
            nav.sendBeacon(url);
        } else {
            $.post(url);
        }
    }
}

export = (category: string, action: string, label?: string, engagementTimeMsec?: number) => {
    const isPageView = action === "pageview";
    const { location, referrer } = document;
    const debug = !ANDROID_APP && isLocalHost(location.hostname);

    hitSequenceNumber++;
    sessionEngaged = sessionEngaged || !isPageView;

    saveSession();

    const qs2: QueryString = {
        v: 2,
        tid: "G-5Z5J094DEV",
        _s: hitSequenceNumber,
        sid: sessionID,
        sct: sessionNumber,
        seg: Number(sessionEngaged)
    };

    const now = new Date();
    qs2["epn.hh"] = now.getUTCHours();
    qs2["epn.dow"] = now.getUTCDay() || 7;

    function addSharedParams(qs: QueryString) {
        qs.cid = clientID;
        if (!ANDROID_APP) {
            qs.dt = env.webApp ? "App (Web)" : "Web";
            qs.dl = location.origin + location.pathname + location.search;
            if (referrer) {
                qs.dr = referrer;
            }
        } else {
            qs.dt = "App (Android)";
        }
    }

    addSharedParams(qs2);

    if (isPageView) {
        qs2.en = "page_view";
        qs2["ep.page_path"] = location.pathname + location.search + location.hash;
    } else {
        qs2.en = action;
        qs2["ep.event_category"] = category;
        if (label) {
            qs2["ep.event_label"] = label;
        }
        if (engagementTimeMsec) {
            qs2._et = engagementTimeMsec;
        }
    }

    if (sendFirstVisit) {
        qs2._fv = 1;
        sendFirstVisit = false;
    }

    if (sendSessionStart) {
        qs2._ss = 1;
        sendSessionStart = false;
    }

    if (debug) {
        qs2._dbg = 1;
    }

    send(GA_ORIGIN + "/g/collect?" + $.param(qs2));
};
