import coverartPopup = require("./coverart-popup");
import createPlayer = require("./create-player");
import env = require("./env");
import initAndroidApp = require("./init-android-app");
import initMediaSession = require("./init-media-session");
import initNature = require("./init-nature");
import initPromoExtensions = require("./init-promo-extensions");
import initUIMediator = require("./init-ui-mediator");
import initVolumeControlMediator = require("./init-vc-mediator");
import intl = require("./intl");
import { createStateMachine } from "./sm";
import trackEvent = require("./track-event");
import { createUI } from "./ui";
import { createVolumeControl } from "./vc";

if (ANDROID_APP) {
    document.write("<script src=cordova.js></script>");
}

onerror = (message, url, line, col, error: any) => {
    if (env.oldEdge && error.number === -2147024891) {
        // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/14146025/
        return;
    }

    trackEvent("UI", "Error", `In ${url || "unknown"} at line ${line}, ${message}`);
};

if (env.webApp) {
    document.title = "fmcafé";
}

if (!ANDROID_APP) {
    trackEvent(null, "pageview");
}

intl.setLang(navigator.languages && navigator.languages[0]
    || navigator.language
    || (navigator as any).userLanguage
);

const sm = createStateMachine();
const ui = createUI();

initNature();
if (!ANDROID_APP) {
    initMediaSession(sm);
}
initUIMediator(ui, sm);

$(() => {
    const body = $("body");

    ui.initMarkup(body);
    initVolumeControlMediator(createVolumeControl(), sm);
    initPromoExtensions(ui, sm);

    if (coverartPopup.ENABLED) {
        coverartPopup.init();
    }

    if (ANDROID_APP) {
        $(document).on("deviceready", () => initAndroidApp(sm));
    } else {
        sm.init(createPlayer);
    }

    body.fadeIn(env.ios ? 0 : 1000);
});
