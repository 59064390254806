declare const cordova: any;

// https://github.com/kitolog/cordova-plugin-timer#sample-usage
declare class nativeTimer {
    public onTick: (tick: number) => void;
    public onStop: () => void;
    public start(delay: number, interval: number): void;
    public stop(): void;
}

function getJSON(url: string, success: (res: any) => void) {
    if (ANDROID_APP) {
        cordova.plugin.utils.getJSON(url, success);
    } else {
        $.getJSON(url, success);
    }
}

function setInterval(callback: () => void, interval: number): any {
    if (ANDROID_APP) {
        const timer  = new nativeTimer();
        timer.onTick = (tick: any) => callback();
        timer.onStop = $.noop;
        timer.start(interval, interval);
        return timer;
    } else {
        return window.setInterval(callback, interval);
    }
}

/** Don't forget to clear the handle variable! */
function clearInterval(handle: any) {
    if (ANDROID_APP) {
        (handle as nativeTimer)?.stop();
    } else {
        window.clearInterval(handle);
    }
}

function setTimeout(callback: () => void, timeout: number) {
    if (ANDROID_APP) {
        let fired = false;
        const handle = setInterval(
            () => {
                if (!fired) {
                    callback();
                    fired = true;
                }
                clearInterval(handle);
            },
            timeout
        );
    } else {
        window.setTimeout(callback, timeout);
    }
}

export { getJSON, setInterval, clearInterval, setTimeout };
