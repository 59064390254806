import androidFriendly = require("./android-friendly");
import env = require("./env");
import intl = require("./intl");
import { ITrackMetadata } from "./metadata";
import IPlayer = require("./player");
import stream = require("./stream");
import trackEvent = require("./track-event");

type PlayerFactory = () => IPlayer;

export const enum STATE {
    EMPTY,
    INIT,
    READY,
    BUFFERING,
    PLAYING,
    STOPPED,
    ERROR
}

export interface IStateMachine {
    readonly stateChanged: JQueryCallback;
    readonly metadataChanged: JQueryCallback;

    state(): STATE;
    playState(): STATE;
    lastError(): string;

    init(playerFactory: PlayerFactory): void;
    play(): void;
    stop(): void;
    setVolume(value: number): void;
}

export function createStateMachine(): IStateMachine {
    const stateChanged = $.Callbacks();
    const metadataChanged = $.Callbacks();

    let lastError = "";
    let state = STATE.EMPTY;
    let player: IPlayer;
    let playTimestamp = 0;
    let stopTimestamp = 0;
    let shouldStopAtPlaying = false;
    let playbackWorkersTimer: any;
    let stallDetectorLastTime: number;

    let metadata: ITrackMetadata;

    function playState() {
        if (!player) {
            return STATE.EMPTY;
        }

        switch (state) {
            case STATE.BUFFERING:
            case STATE.PLAYING:
            case STATE.STOPPED:
                return state;

            case STATE.ERROR:
            case STATE.READY:
                return STATE.STOPPED;
        }

        return STATE.EMPTY;
    }

    function enterState(newState: STATE) {
        if (newState === state) {
            return;
        }

        state = newState;
        stateChanged.fire();
    }

    function enterStoppedState(resetStopTimestamp?: boolean) {
        enterState(STATE.STOPPED);
        shouldStopAtPlaying = false;
        stopTimestamp = resetStopTimestamp ? 0 : Date.now();
    }

    function enterErrorState(message: string) {
        lastError = message;
        metadata = null;
        enterState(STATE.ERROR);
    }

    function handlePlayerError(data: string) {
        if (state === STATE.STOPPED) {
            trackEvent("Player", "Error in STOPPED", data);
            return;
        }

        if (state === STATE.PLAYING) {
            stopTimestamp = Date.now();
        }

        const secondsSinceStop = (Date.now() - stopTimestamp) / 1000;
        if (secondsSinceStop < 120) {
            trackEvent("Player", "Retry on error", data);
            enterState(STATE.BUFFERING);
            androidFriendly.setTimeout(retryOnError, 1000 * (secondsSinceStop < 5 ? 1 : 5));
        } else {
            enterErrorState(intl.t("PLAYER_ERR"));
        }
    }

    function handlePlayerStatusChange(status: string, extra: string) {
        switch (status) {
            case "buffering":
                updateMetadata();
                enterState(STATE.BUFFERING);
                break;

            case "playing":
                updateMetadata();
                enterState(STATE.PLAYING);
                if (shouldStopAtPlaying) {
                    shouldStopAtPlaying = false;
                    stop();
                }
                break;

            case "stopped":
                enterStoppedState();
                break;

            case "error":
                if (shouldStopAtPlaying) {
                    enterStoppedState(true);
                } else {
                    handlePlayerError(extra);
                }
                break;
        }
    }

    function init(playerFacory: PlayerFactory) {
        enterState(STATE.INIT);

        player = playerFacory();

        if (!player) {
            enterErrorState(intl.t("ERR_NO_MP3"));
        } else {
            player.statusChanged.add(handlePlayerStatusChange);
            stateChanged.add(updatePlaybackWorkers);

            enterState(STATE.READY);

            androidFriendly.setInterval(trackMinutePlayed, 60 * 1000);
        }
    }

    function play() {
        if (playState() !== STATE.STOPPED) {
            return;
        }

        playTimestamp = Date.now();
        player.play();
    }

    function retryOnError() {
        player.play();
    }

    function stop() {
        switch (playState()) {
            case STATE.BUFFERING:
                shouldStopAtPlaying = true;
                break;

            case STATE.PLAYING:
                player.stop();
                break;
        }
    }

    function setVolume(value: number) {
        if (player.supportsVolume) {
            player.setVolume(value);
        }
    }

    function trackMinutePlayed() {
        if (playState() === STATE.PLAYING) {
            trackEvent("Player", "Minute played", null, 60 * 1000);

            if (Date.now() - playTimestamp > 6 * 60 * 60 * 1000) {
                trackEvent("Player", "Autostop");
                stop();
            }
        }
    }

    function updatePlaybackWorkers() {
        androidFriendly.clearInterval(playbackWorkersTimer);
        playbackWorkersTimer = null;
        stallDetectorLastTime = null;

        if (state === STATE.PLAYING) {
            playbackWorkersTimer = androidFriendly.setInterval(
                () => {
                    stallDetectorHeartbeat();
                    updateMetadata();
                },
                3 * 1000
            );
        }
    }

    function stallDetectorHeartbeat() {
        if (env.ios) {
            // GA reports the enormous number of 'stalled' retries for iOS
            return;
        }

        if (!player.supportsTimes) {
            return;
        }

        const times = player.getTimes();

        if (times.current > 0 && times.current < times.total) {
            if (times.current === stallDetectorLastTime) {
                handlePlayerError("stalled");
            } else {
                stallDetectorLastTime = times.current;
            }
        }
    }
    function updateMetadata() {
        applyNewMetadata(stream.currentCueAsMetadata());
        stream.fetchCurrentCue();
    }

    function applyNewMetadata(newMetadata: ITrackMetadata) {
        if (!metadata || metadata.artist !== newMetadata.artist || metadata.title !== newMetadata.title) {
            metadata = newMetadata;
            metadataChanged.fire(metadata);
        }
    }

    return {
        stateChanged,
        metadataChanged,

        state: () => state,
        playState,
        lastError: () => lastError,

        init,
        play,
        stop,
        setVolume
    };
}
